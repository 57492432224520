<template>
  <section class="leistungen-slider part">
    <div v-view class="constrain animate in-bottom">
      <div class="header-line">
        <h2 v-html="payload.titel" />
        <lpgmbh-button :to="payload.discoverAllLink" inverted>
          {{ payload.discoverAllText }}
        </lpgmbh-button>
      </div>
      <slider
        ref="sliderRef"
        v-slot="{ item }"
        class="slider"
        :items="payload.leistungen"
        @progress="onProgress"
        @afterInit="updatePage"
        @slideChange="updatePage"
        @resize="updatePage"
      >
        <Go :to="anchorize(item.link)">
          <box class="box" padding background>
            <h3 v-html="item.titel" />
            <span v-html="item.beschreibung" />
          </box>
        </Go>
      </slider>
      <page-progress
        class="progress"
        :start="start"
        :end="end"
        :current-page="page"
        :pages="pages"
        @prev="prev"
        @next="next"
      />
    </div>
  </section>
</template>

<script>
import { ref } from 'vue';
import { anchorize } from '@/utils/anchor';
import { useVisibleProgress, usePage } from '@/composables/swiper';

export default {
  props: { payload: { type: Object, default: () => ({}) } },
  setup() {
    const sliderRef = ref(null);

    const { onProgress, start, end } = useVisibleProgress();
    const { updatePage, page, pages } = usePage();

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    return {
      anchorize,
      sliderRef,
      page,
      pages,
      start,
      end,
      prev,
      next,
      onProgress,
      updatePage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.leistungen-slider {
  @include responsive('margin-top', px(64), px(226));
}

.header-line {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include responsive('margin-bottom', px(24), px(52));
}

.slider {
  @include responsive('overflow', visible, hidden);
}

.leistung-box {
  @apply bg-secondary;
  @apply rounded-big;
  @apply h-full;

  @include padding(px(18) px(24), px(59) px(55));
}

.box {
  height: 100%;
}

.progress {
  @include responsive('margin-top', px(14), px(38))
}
</style>
